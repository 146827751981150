import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import i18next from 'i18next'

export default function About({data}) {

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.page.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.page.edges[1].node.content)
  }

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={storyPage.title + ' | %s'}
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>{storyPage.title}</h1>
          </div>
        </header>
        <section className="common-link">
          <Container className="col-10 col-sm-10 col-lg-6 col-xl-5">
            <Row className="py-4">
              <p><ReactMarkdown plugins={[gfm]} children={storyPage.body[0].content} /></p>
              {/* <h2><ReactMarkdown plugins={[gfm]} children={storyPage.body[1].title} /></h2>
              <p><ReactMarkdown plugins={[gfm]} children={storyPage.body[1].content} /></p> */}
              <h2><ReactMarkdown plugins={[gfm]} children={storyPage.body[2].title} /></h2>
              <p><ReactMarkdown plugins={[gfm]} children={storyPage.body[2].content} /></p>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    page: allStoryblokEntry(filter: {slug: {eq: "about"}})  {
      edges {
        node {
          content
        }
      }
    }
  }
`
